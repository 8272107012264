:root {
  --vendor-color: black;
  --vendor-color-light: black;
  --vendor-hover-color: black;
  --vendor-font-family: "Open Sans", sans-serif;
}

.commonHeader-Div {
  width: 100%;
  height: 41px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01rem;
  font-size: 20px;
}

.commonSection-div {
  display: flex;
  align-items: center;
  color: #837f7f;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 15px;
}

.title-div {
  font-weight: 400;
  font-size: 16px;
  margin-top: -5px;
  margin-bottom: 30px;
}

html[dir="rtl"] .title-div {
  font-size: 17px;
}

.contact-details-label-name {
  font-size: 11px;
  color: #a3a2a2;
  padding: 0 7px;
  font-weight: 500;
  letter-spacing: 0;
  /* padding-bottom: 10px; */
}

.contact-details-label-name1 {
  position: absolute;
  top: 20px;
  transition: 0.2s ease all;
  user-select: none;
}

.contact-form-container input:focus ~ .contact-details-label-name1,
.contact-form-container input:not(:focus):valid ~ .contact-details-label-name1 {
  top: -5px;
  bottom: 0px;
  left: 0px;
}

html[dir="rtl"]
  .contact-form-container
  input:focus
  ~ .contact-details-label-name1,
html[dir="rtl"]
  .contact-form-container
  input:not(:focus):valid
  ~ .contact-details-label-name1 {
  top: -5px;
  bottom: 0px;
  right: 0px;
  left: auto;
}

.contact-details-flag {
  padding: 0;
}

.contact-details-flag button {
  border-bottom: none !important;
}

.contact-details-form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 7px;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  height: 45px;
}

.contact-details-form-control.contact-details-phone-number {
  font-family: var(--vendor-font-family);
}

html[dir="rtl"] .contact-details-form-control.contact-details-phone-number {
  font-family: var(--vendor-font-family);
}

input.contact-details-form-control:focus-visible {
  outline: none;
}

.contact-form-container {
  display: flex;
  gap: 10px;
  border-bottom: 0.25px solid #ccc;
}

.contact-details-form-padding {
  padding: 0 7px;
  border-bottom: 0.25px solid #ccc;
}

.contact-details-form-div {
  margin-bottom: 20px;
  position: relative;
  /* min-height: 65px; */
}

.or-div {
  display: flex;
  gap: 12.46px;
  align-items: center;
  padding: 48px 30px;
  align-items: center;
  height: 22px;
  justify-content: center;
}

.or-line {
  width: 62.881px;
  height: 0px;
  border-bottom: 0.25px solid #ccc;
}

.or-text {
  /* font-weight: 605; */
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000;
  /* color: #a3a2a2; */
  justify-content: center;
}

.contact-details-login-button-div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  color: #000;
}

.contact-details-login-button {
  height: 43px;
  background: #ffffff;
  border: 2px solid #000;
  border-radius: 24px;
  display: flex;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  cursor: pointer;

  width: 199px;
}

.contact-details-login-button:hover {
  background-color: var(--vendor-color);
  color: #fff;
}

.contact-details-bottom-button {
  position: absolute;
  bottom: 20px;
  height: 45px;
  width: 100%;
  left: 0;
  padding-left: 36px;
  padding-right: 36px;
  /* display: grid;
  grid-template-columns: 30% calc(70% - 12px); */
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: center;
}

.contact-details-next-button {
  border-radius: 40px;
  font-size: 16px;
  border: 1.5px solid var(--vendor-color);
  color: #fff;
  padding: 10px;
  display: flex;
  font-weight: 600;
  background-color: var(--vendor-color);
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 45px;
  width: 100%;
  text-align: center;
  /* width: 206px; */
}

.contact-details-back-button {
  border-radius: 40px;
  height: 45px;
  color: var(--vendor-color);
  border: 2px solid var(--vendor-color);
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  /* width: 143px; */
  align-items: center;
}

.contact-details-next-button:hover {
  background-color: var(--vendor-hover-color);
  color: #fff;
}

.contact-details-next-button:active {
  transform: translateY(1px);
}

.contact-details-back-button:hover {
  background-color: var(--vendor-hover-color);
  color: #fff;
}

.contact-details-back-button:active {
  transform: translateY(1px);
}

.background-issue {
  background-color: #fff;
}

.background-issue:hover {
  background-color: var(--vendor-hover-color);
  color: #fff;
}

@media (max-width: 600px) {
  /* .or-div {
    padding: 20px;
  } */
  /* .full-screen-div {
    min-height: 100vh;
  } */
  /* .contact-details-form-div {
    padding-bottom: 10px;
  } */

  .contact-details-bottom-button {
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
  }

  .contact-details-back-button {
    min-width: 100px;
  }

  /* .contact-details-next-button {
    min-width: 205px;
  } */
  .contact-details-label-name {
    padding-bottom: 0px;
  }

  .contact-details-mobile-button {
    position: fixed;
  }

  .review-order-mobile-button {
    bottom: 0;
    padding-bottom: 20px;
    padding-top: 10px;
    height: auto !important;
  }

  .review-order-mobile-button .contact-details-back-button:hover {
    background-color: white;
    color: var(--vendor-color);
  }
}

.fashion-theme .contact-details-back-button {
  border-radius: 0px;
}

.fashion-theme .contact-details-next-button {
  border-radius: 0px;
}

.holder-container {
  padding: 26px 36px 69px 36px;
  min-height: calc(100vh - 80px);
  /* min-height: -webkit-fill-available; */
  /* position: relative; */
}

.fashion-theme-border {
  border-radius: 0 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.commonback-maindiv {
  display: flex;
  margin: 16px 0;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  position: relative;
  min-height: 42px;
}

.commonback-holder {
  height: 42px;
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 9px 10px;
  cursor: pointer;
  position: absolute;
  left: 16px;
}

.commonback-holder-dark {
  background-color: rgba(255, 255, 255, 0.5);
}

.commonback-holder-light {
  background: rgba(73, 71, 71, 0.07);
}

html[dir="rtl"] .commonback-holder {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  left: auto;
  right: 16px;
}

.commonback-heading {
  color: #000;
  font-size: 18px;
  font-weight: 600;
}

html[dir="rtl"] .commonback-heading {
  font-size: 22px;
}

.sideview-fixed {
  position: fixed !important;
  width: auto;
  right: 0;
}

html[dir="rtl"] .sideview-fixed {
  /* position: fixed !important;
  width: auto; */
  left: 0;
  right: auto;
}

.product-details-backbutton {
  position: absolute;
  margin-top: 20px;
  z-index: 30;
  left: 16px;
}

html[dir="rtl"] .product-details-backbutton {
  right: 16px;
}

.otpSentText {
  font-size: 11px;
  color: #a3a2a2;
}

html[dir="rtl"] .otpSentText {
  font-size: 13px;
}

.enterOtpText {
  font-size: 13px;
  font-weight: 500;
}

html[dir="rtl"] .enterOtpText {
  font-size: 14px;
}

.phoneNumberDiv {
  font-size: 20px;
  font-weight: 500;
}

.resendOtpText {
  font-size: 11px;
}

.resendOtpText span {
  text-decoration: underline;
  cursor: pointer;
}

.resendOtpText.disabled span {
  color: #adb5bd;
  cursor: not-allowed;
}

html[dir="rtl"] .resendOtpText {
  font-size: 12px;
}

.resendOtpTimer {
  font-size: 14px;
  color: #0063f6;
}

html[dir="rtl"] .resendOtpTimer {
  font-size: 16px;
}

@media (max-width: 600px) {
  .product-details-backbutton {
    position: absolute;
    margin-top: 0px;
    left: 0px;
  }
}

@media (max-width: 991px) {
  .contact-details-mobile-button {
    position: fixed;
  }

  .review-order-mobile-button {
    bottom: 0;
    padding-bottom: 20px;
    padding-top: 10px;
    height: auto !important;
  }
}

.commonback-holder:active {
  transform: translateY(1px);
}

.toasterDiv {
  font-size: 16px;
}

html[dir="rtl"] .toasterDiv {
  font-size: 18px;
}

.sentOtpDiv {
  margin-top: 10px;
  color: #636363;
  font-size: 11px;
  font-weight: 400;
}

html[dir="rtl"] .sentOtpDiv {
  font-size: 12px;
}

.blueBox {
  background-color: #eefdff;
  padding: 20px 15px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.sentImg {
  height: 23px;
  width: 23px;
}

.inputFlag {
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputFlag .ReactFlagsSelect-module_flagsSelect__2pfa2 {
  margin-right: 5px !important;
}

.inputFlag ul {
  right: auto;
}

html[dir="rtl"] .inputFlag ul {
  left: auto;
}

.form__group {
  position: relative;
  padding: 15px 0 0;
  /* width: calc(100% / 2 - 10px); */
  width: 100%;
  /* max-width: 200px; */
}

.customerInputsFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}

.formSemi {
  /* max-width: 275px; */
  display: flex;
  align-items: center;
  gap: 7px;
}

.form__field::placeholder {
  color: transparent;
}

.form__field:placeholder-shown ~ .form__label {
  font-size: 14px;
  cursor: text;
  font-weight: 300;
  top: 28px;
  left: 11px;
}

html[dir="rtl"] .form__field:placeholder-shown ~ .form__label {
  right: 11px;
}

.form__field:focus {
  border-image-slice: 1;
  font-weight: 400;
}

.form__field:focus ~ .form__label,
.form__field:autofill ~ .form__label,
.form__field:-webkit-autofill ~ .form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 11px;
  color: #000;
  font-weight: 400;
  left: 0;
}

.form__field:focus ~ .form__label.phoneLabel,
.form__field:autofill ~ .form__label.phoneLabel,
.form__field:-webkit-autofill ~ .form__label.phoneLabel {
  left: 0;
}

html[dir="rtl"] .form__field:focus ~ .form__label,
html[dir="rtl"] .form__field:autofill ~ .form__label,
html[dir="rtl"] .form__field:-webkit-autofill ~ .form__label {
  left: auto;
  right: 0;
}

.form__field:required,
.form__field:invalid {
  box-shadow: none;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border: 1px solid #e1e1e1;
  outline: 0;
  font-size: 14px;
  color: #000;
  padding: 4px 10px;
  font-weight: 400;
  background: transparent;
  transition: border-color 0.2s;
  border-radius: 10px;
  height: 44px;
}

html[dir="rtl"] .form__field {
  font-size: 16px;
}

html[dir="rtl"] .form__field:placeholder-shown ~ .form__label {
  font-size: 16px;
}

html[dir="rtl"] .form__field:focus ~ .form__label {
  font-size: 13px;
  top: -4px;
  right: 0;
}

html[dir="rtl"] .form__field:focus ~ .form__label.phoneLabel,
html[dir="rtl"] .form__field:autofill ~ .form__label.phoneLabel,
html[dir="rtl"] .form__field:-webkit-autofill ~ .form__label.phoneLabel {
  right: 0;
  left: auto;
  top: -4px;
}

.form__label.phoneLabel.dataFilled {
  left: 0;
  right: auto;
}

html[dir="rtl"] .form__label.phoneLabel.dataFilled {
  left: auto;
  right: 0;
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 11px;
  font-weight: 500;
  color: #000;
}

.form__field:placeholder-shown ~ .form__label.phoneLabel {
  left: 95px;
}

.form__field:focus ~ .form__label.phoneLabel,
.form__field:autofill ~ .form__label.phoneLabel,
.form__field:-webkit-autofill ~ .form__label.phoneLabel {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 11px;
  color: #000;
  font-weight: 400;
  left: 0;
}

html[dir="rtl"] .form__field:placeholder-shown ~ .form__label.phoneLabel {
  right: 95px;
}

html[dir="rtl"] .form__field:focus ~ .form__label.phoneLabel,
html[dir="rtl"] .form__field:autofill ~ .form__label.phoneLabel,
html[dir="rtl"] .form__field:-webkit-autofill ~ .form__label.phoneLabel {
  right: 0;
}

/* .hideBorder {
  border: none !important;

} */

.hideBorder .phoneLabel {
  left: 0;
}

.customerInputError {
  border-color: #c00 !important;
}

.flagWrap {
  border: 0.25px solid #a4b4c8;
  border-radius: 5px;
  height: 32px;
}

.changeHeight {
  height: 80px !important;
  width: 100% !important;
}
