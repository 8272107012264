.side-bar-backdrop {
  min-height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.54);
  backdrop-filter: blur(2.5px);
  position: absolute;
  overflow: hidden;
  left: 0;
  top: 0;
  z-index: 7777;
}

.vendor-card {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}

.sideMenu-mainDiv::-webkit-scrollbar {
  display: none;
}

.sideMenu-mainDiv {
  min-height: 100vh;
  overflow-y: scroll;
  width: 90%;
  position: relative;
  left: -90vw;
  background: white;
  border-radius: 0px 20px 20px 0px;
  padding: 36px 8px 57px 22px;
  border-right: 1px solid #000;
  -webkit-animation: slide-new 0.5s forwards;
  -webkit-animation-delay: 0.2s;
  animation: slide-new 0.5s forwards;
  animation-delay: 0.2s;
}

html[dir="rtl"] .sideMenu-mainDiv {
  padding: 36px 22px 57px 8px;
  border-radius: 20px 0px 0px 20px;
  left: 0;
  right: -90vh;
  -webkit-animation: slide-arabicnew 0.5s forwards;
  -webkit-animation-delay: 0.2s;
  animation: slide-arabicnew 0.5s forwards;
  animation-delay: 0.2s;
}

.commonBackButton-mainDiv {
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
  margin-bottom: 20px;
}

.commonBackButton-backdiv {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 12px;
  cursor: pointer;
}

.commonBackButton-crossdiv {
  display: flex;
  align-items: center;
  justify-content: end;
  cursor: pointer;
  padding-right: 25px;
  height: 24px;
}

html[dir="rtl"] .commonBackButton-crossdiv {
  padding-left: 25px;
  padding-right: 0;
}

.commonBackButton-backdiv-text {
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  display: flex;
  align-items: center;
  line-height: normal;
}

html[dir="rtl"] .commonBackButton-backdiv-text {
  font-size: 19px;
}

html[dir="rtl"] .commonBackButton-backdiv-arrow {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.vendor-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 43px;
}

.vendor-card-logo {
  height: 84px;
  width: 84px;
  border-radius: 84px;
}

.vendor-card-name {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

html[dir="rtl"] .vendor-card-name {
  font-size: 36px;
}

.section-header {
  color: #837f7f;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

html[dir="rtl"] .section-header {
  font-size: 24px;
}

.section-holder {
  padding-top: 26px;
  padding-left: 26px;
}

html[dir="rtl"] .section-holder {
  padding-top: 26px;
  padding-right: 26px;
}

.section-name-div {
  gap: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 18px;
  margin-bottom: 20px;
  border-bottom: 0.5px solid #e3e3e3;
}

.section-name-div:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}

.section-name-text {
  color: #000;
  height: 25px;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  overflow: hidden;
}

html[dir="rtl"] .section-name-text {
  font-size: 24px;
}

.section-name-arrow {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  margin-right: 19px;
}

html[dir="rtl"] .section-name-arrow {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  margin-left: 19px;
}

.sections-name-arrow {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  margin-right: 19px;
}

.sections-name-arrow path {
  stroke: #837f7f;
}

html[dir="rtl"] .sections-name-arrow {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  margin-left: 19px;
}

.restSide-div {
  padding-bottom: 18px;
  margin-bottom: 24px;
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-bottom: 0.5px solid #e3e3e3;
  cursor: pointer;
}

.restSide-div:last-child {
  border-bottom: none;
}

html[dir="rtl"] .restSide-div {
  font-size: 24px;
}

.sideMenu-bottomImage {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sideMenu-bottom-mainDiv {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding-bottom: 57px;
}

html[dir="rtl"] .sideMenu-bottom-mainDiv {
  right: 0;
}

.poweredBy-text {
  color: #000;
  text-align: center;
  font-size: 15px;
  /* font-style: italic; */
  font-weight: 200;
  line-height: normal;
  margin-bottom: 7px;
}

.privacy-policy-text {
  padding-top: 19px;
  padding-bottom: 13px;
  border-top: 0.5px solid #e3e3e3;
  border-bottom: 0.5px solid #e3e3e3;
  display: flex;
  justify-content: space-between;
  color: #000;
  text-align: center;
  font-size: 12px;
  /* font-style: italic; */
  font-weight: 600;
  margin: 0 20px;
  margin-bottom: 45px;
  line-height: normal;
}

html[dir="rtl"] .privacy-policy-text {
  font-size: 16px;
}

.reach-Us-Via-text {
  color: #000;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

html[dir="rtl"] .reach-Us-Via-text {
  font-size: 19px;
}

.sidemenu-social-icon {
  margin-bottom: 38px;
}

@-webkit-keyframes slide-new {
  100% {
    left: 0;
  }
}

@keyframes slide-new {
  100% {
    left: 0;
  }
}

@-webkit-keyframes slide-arabicnew {
  100% {
    right: 0;
  }
}

@keyframes slide-arabicnew {
  100% {
    right: 0;
  }
}

.sideMenu-BottomIcons {
  display: flex;
  gap: 15px;
  /* align-items: center; */
  justify-content: center;
  margin-top: 10px;
}

.hide-overflow {
  overflow-y: auto !important;
  overflow-x: hidden;
}

.hide-overflow::-webkit-scrollbar {
  display: none;
}

.sideview-hide-overflow {
  overflow-y: hidden;
  overflow-x: hidden;
  height: 100vh;
}

.sideview-hide-overflow::-webkit-scrollbar {
  display: none;
}