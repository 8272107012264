:root {
  --vendor-color: black;
  --vendor-color-light: black;
  --vendor-hover-color: black;
  --vendor-font-family: "Open Sans", sans-serif;
}

.delivery-address-area-DIv {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  padding-bottom: 0;
  color: #000;
  font-weight: 600;
  font-size: 16px;
  align-items: center;
}

.delivery-address-area-DIv:hover {
  color: #000;
}

.delivery-selected-address {
  display: flex;
  gap: 5px;
  justify-content: space-between;
  color: var(--vendor-color);
  align-items: center;
}

.delivery-selected-address .right-arrow {
  margin-right: 5px;
  margin-left: 10px;
  font-size: 27px;
}

.delivery-selected-address-area {
  font-size: 14px;
  color: #000;
  font-weight: 600;
}

html[dir="rtl"] .delivery-selected-address-area {
  font-size: 15px;
}

html[dir="rtl"] .delivery-selected-address .right-arrow {
  margin-right: 20px;
  margin-left: 5px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.delivery-address-address-select {
  padding-bottom: 20px;
}

.delivery-address-address-select .delivery-address-heading-text {
  color: #a3a2a2;
  font-size: 16px;
  padding-bottom: 20px;
}

.delivery-address-type-select {
  border: none;
  margin-top: 20px;
  padding-bottom: 2px;
  display: grid;
  gap: 8px;
  background-color: white;
  grid-template-columns: repeat(3, minmax(0px, 1fr));
  position: -webkit-sticky;
  position: sticky;
  top: 48px;
  z-index: 1000;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.delivery-address-type-select .delivery-address-type {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-height: 90px;
}

.deliver-type-active {
  background-color: #eaf9fa;
  border-radius: 8px;
  padding: 10px;
}

.delivery-address-type-select .delivery-address-type .Address-icon-names div {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .delivery-address-type-select
  .delivery-address-type
  .Address-icon-names
  .navlink-icon {
  background-color: #fff;
} */
.delivery-type-text {
  color: #000;
  font-weight: 500;
  font-size: 16px;
  margin-top: 10px;
}

html[dir="rtl"] .delivery-type-text {
  font-size: 17px;
}

.delivery-address-container1 {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(3, minmax(0px, 1fr));
  margin: 25px 0;
  height: 48px;
}

.delivery-address-container2 {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(2, minmax(0px, 1fr));
  margin-bottom: 25px;
}

.delivery-address-form {
  position: relative;
  min-height: 48px;
  display: flex;
  align-items: end;
}

.delivery-address-form-div1 .label-name {
  font-size: 13px;
  height: 20px;
  display: flex;
  align-items: center;
  font-weight: 500;
  padding: 0 7px;
  position: absolute;
  top: 17px;
  user-select: none;
  transition: 0.2s ease all;
}

.delivery-address-form input:focus {
  border: none;
  box-shadow: none;
  border: 1px solid #e1e1e1;
}

.delivery-address-form input:focus ~ .label-name,
.delivery-address-form input:not(:focus):valid ~ .label-name {
  top: -14px;
  bottom: 0;
  left: 0;
}

html[dir="rtl"] .delivery-address-form input:focus ~ .label-name,
html[dir="rtl"] .delivery-address-form input:not(:focus):valid ~ .label-name {
  top: -15px;
  bottom: 0;
  right: 0;
  left: auto;
}

.delivery-address-form-div .label-name {
  font-size: 13px;
  height: 20px;
  display: flex;
  align-items: center;
  font-weight: 500;
  padding: 0 7px;
  position: absolute;
  top: 17px;
  user-select: none;
  transition: 0.2s ease all;
}

.delivery-address-form-div1 .form-control {
  border: 1px solid #e1e1e1;
  border-radius: 9px;
  font-weight: 500;
  font-size: 14px;
  padding: 7px;
  box-shadow: none;
  color: #000;
  height: 43px;
}

.delivery-address-form-div1 .delivery-selected-address {
  border: 1px solid #e1e1e1;
  border-radius: 9px;
  padding: 7px;
}

.delivery-address-form-div .form-control {
  border: 1px solid #e1e1e1;
  font-weight: 500;
  font-size: 14px;
  border-radius: 9px;
  color: #000000;
  padding: 7px;
  height: 43px;
}

.delivery-address-form-div .form-control:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid #e1e1e1;
}

html[dir="rtl"] .delivery-address-form-div1 .label-name {
  font-size: 14px;
}

html[dir="rtl"] .delivery-address-form-div .label-name {
  font-size: 14px;
}

html[dir="rtl"] .delivery-address-form-div1 .form-control {
  font-size: 15px;
}

html[dir="rtl"] .delivery-address-form-div .form-control {
  font-size: 15px;
}

.disable-input {
  background-color: #fff !important;
  cursor: not-allowed;
}

.divider-class {
  height: 0px;
  border-top: 0.25px solid #ccc;
  margin: 20px 0;
  margin-bottom: -20px;
}

.fashion-theme .contact-details-back-button,
.fashion-theme .contact-details-next-button {
  border-radius: 0px;
}

.delivery-details-text {
  height: 30px;
  /* color: #ccc; */
  color: rgb(161, 161, 161);
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
}

html[dir="rtl"] .delivery-details-text {
  font-size: 21px;
}

/* @media (max-width: 600px) {

  .delivery-address-form-div1 .form-control,
  .delivery-address-form-div .form-control {
    height: 20px;
    font-size: 14px;
    padding: 0 8px;
  }

  html[dir="rtl"] .delivery-address-form-div1 .form-control,
  html[dir="rtl"] .delivery-address-form-div .form-control {
    font-size: 18px;
  }
} */

.delivery-form-field {
  width: 100%;
}

.map-border {
  border-radius: 8px;
}

.full-width {
  width: 100%;
}

.custom-placeholder::placeholder {
  color: #b3b3b3 !important;
  font-size: 14px !important;
  font-weight: 500;
}

html[dir="rtl"] .custom-placeholder::placeholder {
  font-size: 15px !important;
}

/* .rounded-checkbox {
  width: 16px;
  height: 16px;
  appearance: none;
  border-radius: 50%;
  border: 2px solid #000;
  outline: none;
  cursor: pointer;
  display: inline-block;
  position: relative;
}

.rounded-checkbox:checked {
  background-color: #007bff;
  border-color: #007bff;
}

.rounded-checkbox:checked::after {
  content: "✔";
  color: white;
  font-size: 14px;
  font-weight: bold;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
} */

.round {
  position: relative;
  display: flex;
  align-items: center;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 17px;
  left: 0;
  position: absolute;
  top: 0;
  width: 17px;
}

.round label:after {
  border: 1.5px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 5px;
  left: 2.5px;
  opacity: 0;
  position: absolute;
  top: 4px;
  transform: rotate(-45deg);
  width: 10px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #007bff;
  border-color: #007bff;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
