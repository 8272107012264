.verifiedDiv {
    position: absolute;
    right: 10px;
    top: 25px;
    display: flex;
    align-items: center;
    color: #fff;
    padding: 5px 10px;
    background-color: #4CAF50;
    border-radius: 20px;
    font-size: 12px;
    gap: 5px;
}