#area-modal-container {
  position: absolute;
  display: table;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  transform: scale(0);
  z-index: 9999;
}

#area-modal-container.open {
  transform: scale(1);
}

#area-modal-container.open .modal-background {
  background: rgba(0, 0, 0, 0);
  animation: fadeIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

#area-modal-container.open .modal-background .modal {
  opacity: 0;
  animation: scaleUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

#area-modal-container.open + .content {
  animation: scaleBack 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

#area-modal-container.open.out {
  animation: quickScaleDown 0s 0.5s linear forwards;
}

#area-modal-container.open.out .modal-background {
  animation: fadeOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

#area-modal-container.open.out .modal-background .modal {
  animation: scaleDown 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

#area-modal-container.open.out + .content {
  animation: scaleForward 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

#area-modal-container .modal-background {
  display: table-cell;
  background: rgba(0, 0, 0, 0.8);
  text-align: center;
  vertical-align: middle;
}

#area-modal-container .modal-background .modal {
  /* background: white; */
  /* padding: 20px; */
  display: flex;
  align-items: center;
  border-radius: 3px;
  font-weight: 300;
  /* position: relative;
      height: 100vh;
      width: 100vw; */
}

.content {
  min-height: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
}

/* Animation part */

@keyframes fadeInforDrawer {
  0% {
    background: rgba(222, 222, 222, 0.41);
  }

  100% {
    background: rgba(222, 222, 222, 0.41);
  }
}

@keyframes fadeIn {
  0% {
    background: rgba(0, 0, 0, 0);
  }

  100% {
    background: rgba(0, 0, 0, 0.7);
  }
}

@keyframes scaleUp {
  0% {
    transform: scale(0.8) translateY(1000px);
    opacity: 0;
  }

  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
}

@keyframes scaleBack {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.85);
  }
}

@keyframes quickScaleDown {
  0% {
    transform: scale(1);
  }

  99.9% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes fadeOut {
  0% {
    background: rgba(0, 0, 0, 0.7);
  }

  100% {
    background: rgba(0, 0, 0, 0);
  }
}

@keyframes scaleDown {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }

  100% {
    transform: scale(0.8) translateY(1000px);
    opacity: 0;
  }
}

@keyframes scaleForward {
  0% {
    transform: scale(0.85);
  }

  100% {
    transform: scale(1);
  }
}

.paylinkCard {
  background-color: white;
  margin: 0 auto 20px;
  padding: 25px 25px;
  max-width: 620px;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.paymentCardGrow {
  flex-grow: 1;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.paymentTypeMain {
  margin-top: 20px;
  border-top: #c7c7c7 solid 1px;
  padding-top: 15px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.firstDetailsTab {
  border-top: none !important;
  padding-top: 0 !important;
  margin-top: 0 !important;
}

.scrollbarPaylinkCard::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

.scrollbarPaylinkCard::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.scrollbarPaylinkCard::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.goBackButton {
  text-align: right;
  width: 100%;
  margin-bottom: -5px;
  margin-top: -10px;
}

.goBackButton img {
  height: 18px;
  cursor: pointer;
}

.payViaText {
  font-weight: 300;
}

.backButtonDiv {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 620px;
  width: 100%;
  margin: 0 auto 15px;
}

.backButton {
  background: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  color: #4a4a4a;
  border-radius: 30px;
  width: 100%;
  padding: 12px 0;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  border: none;
}

html[dir="rtl"] .scrollbarPaylinkCard {
  padding-right: 10px !important;
  direction: ltr;
}

html[dir="rtl"] .backButton {
  font-size: 19px;
  font-family: "Orleen";
}

html[dir="rtl"] .changeDir {
  direction: rtl;
}

.lognButtonMainDiv {
  display: grid;
  grid-template-columns: 35px auto 10px;
  border-radius: 8px;
  border: 1px solid #000;
  background: #fff;
  padding: 8px 15px;
  gap: 20px;
  /* margin-bottom: 6px; */
  margin: 8px 0;
}

.buttonText {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: start;
}

html[dir="rtl"] .buttonText {
  font-size: 16px;
}

.image {
  width: 100%;
  aspect-ratio: 3/2;
  object-fit: contain;
}

.imageDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  width: 34.177px;
}

.arrow {
  display: flex;
  justify-content: center;
  align-items: center;
}

html[dir="rtl"] .arrow {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.shortButtonDiv {
  border: 1px solid #000;
  background: #fff;
  width: 161px;
  border-radius: 8px;
  height: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.shortButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.selectedButton {
  background-color: #000;
  color: #fff !important;
}

@media (max-width: 700px) {
  .paylinkCard {
    margin: 0 10px 15px;
    padding: 20px 20px;
  }

  .paymentCardGrow {
    margin: 0 10px 15px;
    padding: 30px 5px;
  }

  .backButtonDiv {
    margin: 0 0px 15px;
    width: calc(100% - 10px);
  }
}
