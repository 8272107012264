.delivery-info-mainDIv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding-bottom: 5px;
  margin-top: 5px;
  margin-bottom: 20px;
  border-bottom: 0.25px solid #ccc;
}

.newreview-button-div {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: rgba(73, 71, 71, 0.07);
}

html[dir="rtl"] .newreview-button-div {
  right: 0;
}

.buyer-details-firstDiv div.review-order-text {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  color: #000;
  height: 30px;
}

html[dir="rtl"] .buyer-details-firstDiv div.review-order-text {
  font-size: 16px;
}

.newreview-details-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 20px;
  border-radius: 8px;
  height: 45px;
  background-color: #eaf9fa;
  margin: 20px 0;
}

.newreview-text {
  min-height: 30px;
  display: flex;
  font-weight: 700;
  align-items: center;
  vertical-align: middle;
  font-size: 16px;
}