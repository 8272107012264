.prevOrderCard-MainDiv {}

.borderDivider {
  border-bottom: 0.5px solid #e3e3e3;
  margin: 20px 0;
}

.dateTimeContainer {
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 3px;
  font-weight: 600;
}

html[dir="rtl"] .dateTimeContainer {
  font-size: 15px;
}

.orderInfoContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.orderNoText {
  font-size: 12px;
  color: #858485;
  font-weight: 600;
}

html[dir="rtl"] .orderNoText {
  font-size: 15px;
}

.ItemsCart {
  font-size: 13px;
  font-weight: 500;
}

html[dir="rtl"] .ItemsCart {
  font-size: 16px;
}

.orderNoValue {
  font-size: 14px;
  font-weight: 600;
}

html[dir="rtl"] .orderNoValue {
  font-size: 17px;
}

.reorderButton {
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid var(--vendor-color);
  color: var(--vendor-color);
  border-radius: 4px;
  cursor: pointer;
}

.filledCircle {
  height: 3px;
  width: 3px;
  border-radius: 50%;
  background-color: #a3a2a2;
}

.statusDiv {
  font-size: 12px;
  color: #ffffff;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

html[dir="rtl"] .statusDiv {
  font-size: 15px;
}

.orderAgainButton {
  color: var(--vendor-color);
  font-size: 14px;
  border: 1px solid var(--vendor-color);
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
}

.preOrderProductImg {
  height: 80px;
  width: 80px;
  border-radius: 6px;
}

.prevOrder-orderID {
  display: flex;
  align-items: end;
  justify-content: space-between;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  height: 25px;
}

html[dir="rtl"] .prevOrder-orderID {
  font-size: 20px;
}

.prevOrder-status {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 25px;
  /* margin-top: 8px; */
}

html[dir="rtl"] .prevOrder-status {
  font-size: 18px;
}

.prevOrder-dates {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 25px;
  justify-content: space-between;
}

html[dir="rtl"] .prevOrder-dates {
  font-size: 18px;
}

.prevOrder-arrow {
  display: flex;
  justify-content: end;
  align-items: center;
}

.prevOrder-arrow .right-arrow {
  font-size: 25px;
  line-height: 0;
}

html[dir="rtl"] .prevOrder-arrow .right-arrow {
  margin-right: 20px;
  margin-left: 5px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.prevOrder-Image {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 74px;
  width: 74px;
  overflow: hidden;
  border-radius: 15px;
  padding: 0 8px;
}

.recentOrder-Text {
  display: flex;
  height: 30px;
  color: #837f7f;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 25px 0 30px;
}

html[dir="rtl"] .recentOrder-Text {
  font-size: 24px;
}

.noMoreOrder-Text {
  color: #a3a2a2;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}

html[dir="rtl"] .noMoreOrder-Text {
  font-size: 24px;
}

.prevOrder-price {
  font-size: 14px;
  font-weight: 600;
  margin-top: 5px;
}

html[dir="rtl"] .prevOrder-price {
  font-size: 15px;
  font-weight: 600;
}

.orderHistoryMain {
  margin-top: 15px;
}

.monthText {
  font-size: 16px;
  font-weight: 500;
}

html[dir="rtl"] .monthText {
  font-size: 19px;
}

.historyFirstCard {
  display: flex;
  align-items: center;
  gap: 17px;
}

.historySecondCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
  text-align: end;
  align-items: end;
}

.orderNumber {
  font-size: 14px;
  font-weight: 500;
}

html[dir="rtl"] .orderNumber {
  font-size: 18px;
}

.orderDesc {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}

.orderStatusDiv {
  /* height: 25px; */
  padding: 2px 5px;
  text-align: center;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
}

html[dir="rtl"] .orderStatusDiv {
  font-size: 13px;
}

.historycard {
  border: 1px solid #e1e1e1;
  border-radius: 9px;
  padding: 15px 20px;
  margin-top: 7px;
  display: flex;
  flex-direction: column;
}

.historyRow {
  width: 100%;
  padding: 10px 0;
  cursor: pointer;
}

.full-width-divider {
  width: 100%;
  height: 1px;
  background-color: #e1e1e1;
  margin: 10px 0;
}