html[dir="rtl"] .commonSection-div {
  font-size: 24px;
}

html[dir="rtl"] .contact-details-label-name {
  font-size: 15px;
}

html[dir="rtl"] .or-text {
  font-size: 18px;
}

html[dir="rtl"] .contact-details-next-button,
html[dir="rtl"] .contact-details-back-button {
  font-size: 20px;
}

html[dir="rtl"] .contact-details-login-button {
  font-size: 20px;
}